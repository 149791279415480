<!--<template>-->
    <!--<template v-if="content.wandelroute.video !== undefined && content.wandelroute.video !== ''">-->
        <!--<VideoLayout v-bind:detail="content.wandelroute"/>-->
    <!--</template>-->
    <!--<template v-else>-->
        <!--<PhotoLayout v-bind:detail="content.wandelroute"/>-->
    <!--</template>-->
<!--</template>-->
<template>
    <div class="content wandel">
        <div class="container">
            <div class="flex">
                <div class="flex-col flex-col-xs--12">
                    <div class="qr-code">
                        <img :src="getImageUrl(content.wandelroute.img)" alt="">
                    </div>
                </div>
                <div class="flex-col flex-col-xs--12 flex-col-offset-md-1 flex-col-md--10">
                    <h1 v-html="content.wandelroute.title"></h1>
                    <p class="text--large" v-html="content.wandelroute.text"></p>
                    <div class="flex">
                        <div v-for="item in content.wandelroute.routes" v-bind:key="item.name" class="flex-col flex-col-xs--12 flex-col-md--3">
                            <h2 class="text--primary" v-html="item.name"></h2>
                            <p v-html="item.text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VideoLayout from '../components/VideoLayout.vue';
import PhotoLayout from '../components/PhotoLayout.vue';

library.add(faTh);

@Options({
    components: {
        FontAwesomeIcon,
        VideoLayout,
        PhotoLayout
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
    }
})
export default class Detail extends Vue {}
</script>
