<template>
    <div class="content">
        <div class="container">
            <div class="flex">
                <div class="flex-col flex-col-xs--12">
                    <div class="qr-code">
                        <img :src="getImageUrl(content.stadsklap.qr)" alt="">
                    </div>
                </div>
                <div class="flex-col flex-col-xs--12 flex-col-offset-md-1 flex-col-md--10">
                    <h1 v-html="content.stadsklap.title"></h1>
                    <p class="text--large" v-html="content.stadsklap.text"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTh);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
    },
    props: {
        detail: Object
    }
})
export default class PhotoLayout extends Vue {}
</script>
